import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import VueSecureHTML from 'vue-html-secure'
Vue.use(VueSecureHTML)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import './assets/styles/main.sass'
import App from './App.vue'
import { store } from './store'

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
