export const topMenu = {
  menuItems: [
    {
      label: 'Преимущества',
      component: 'ExpAdvantages',
    },
    {
      label: 'Формы',
      component: 'ShippingFormContainer',
    },
    {
      label: 'Проекты',
      component: 'EcosystemLinks',
    },
    {
      label: 'Клиенты',
      component: 'ExpPartners',
    },
    {
      label: 'Соц. сети ',
      component: 'SocialMedia',
    },
  ],
  links: {
    phone: '+7 918 636-81-78',
    email: 'logist@pt-exp.ru',
  },
}

export const headerBlock = {
  image: 'header.png',
  image_mobile: 'header_mobile.jpg',
  title: 'Перевозчик №1 <br> зерна на <br> Новороссийск',
  description:
    'Приложение по организации зерновых перевозок для аккредитованных перевозчиков',
  button: 'Узнать больше',
}

export const appBlock = {
  grey: true,
  subtitle: 'Наше приложение',
  title:
    'С помощью приложения мы <span>обеспечиваем</span> своих заказчиков <span>проверенными исполнителями в короткие сроки</span>',
}

export const clientsBlock = {
  grey: false,
  subtitle: 'Клиенты',
  title:
    'Компании, которые доверяют <span>нашим продуктам на протяжении 10 лет</span>',
}

export const markBlock = {
  subtitle: 'Показатель',
  title:
    'С 2019 года с помощью нашего приложения перевезено более <span>2&nbsp;000&nbsp;000</span> тонн зерна',
}

export const startBlock = {
  grey: false,
  subtitle: 'Начните сейчас',
  title:
    '<span>Оформите заявку</span> на перевозку или на покупку зерна и менеджер свяжется с вами в течении часа',
}
export const ecosystemBlock = {
  grey: false,
  subtitle: 'Экосистема',
  title:
    'В IT экосистему Порт Транзит встроено три продукта, которые <span>комплексно решают проблемы и задачи участников логистического рынка</span>',
}
export const advantagesBlock = {
  grey: false,
  subtitle: 'Наши преимущества',
  title:
    'Помогаем организовать зерновые перевозки <br> <span>для 30 000 аккредитованных перевозчиков</span>',
  tiles: [
    {
      title: '01',
      text: '15 тыс тонн в неделю <br> с гарантией',
    },
    {
      title: '02',
      text: 'Воронеж 1000 км + 50 авто в день - не проблема',
      background: true,
    },
    {
      title: '03',
      text: 'Аккредитованные исполнители в короткие сроки',
    },
    {
      img: 'decision1.png',
    },
    {
      img: 'decision2.png',
    },
    {
      title: '04',
      text: 'Быстрый обмен документами <br> с помощью ЭДО',
    },
    {
      title: '05',
      text: 'Надежная организация перевозки “под ключ“',
      background: true,
    },
  ],
}

// данные формы перевозки
export const formDataToShipping = {
  placeholders: {
    from: 'Откуда вы хотите доставить*',
    to: 'Куда вы хотите доставить*',
    volume: 'Объем (в тоннах)*',
    phone_number: 'Номер телефона*',
    fio: 'ФИО',
  },
  button: 'Отправить',
  checkbox: {
    text: 'Я согласен с ',
    link: 'политикой конфиденциальности',
    url: '/policy.pdf',
  },
}

// данные формы покупки
export const formDataToBuy = {
  placeholders: {
    place: 'Место закупки*',
    phone_number: 'Номер телефона*',
    fio: 'ФИО',
  },
  button: 'Отправить',
  checkbox: {
    text: 'Я согласен с ',
    link: 'политикой конфиденциальности',
    url: '/policy.pdf',
  },
}

export const socialBlock = {
  eco: true,
  title:
    'Ближайшие погрузки вы можете отследить в <span>социальных сетях</span>',
  button: 'Перейти',
  tiles: [
    {
      title: 'Порт транзит. </br> Работа зерновозам.',
      qr: 'work_zerno.svg',
      link: 'https://t.me/ptworks',
    },
    {
      title: 'Погрузки Ростов и Азов',
      qr: 'rostov_azov.svg',
      link: 'https://chat.whatsapp.com/FQffC68IPYd9e0FWSBmwaN',
    },
    {
      title: 'Погрузки на Новороссийск',
      qr: 'novoros.svg',
      link: 'https://chat.whatsapp.com/BWBEMVmWq7RHsdWT6TWgSF',
    },
    {
      title: 'Погрузки на Тамань',
      qr: 'taman.svg',
      link: 'https://chat.whatsapp.com/HdFM0PMQr9J1ykb4ajYqsd',
    },
  ],
}

export const ecosystemLinksBlock = {
  eco: true,
  tiles: [
    {
      title: 'Экспедирование ',
      description:
        'Экспедирование (автоматизация зерновых грузоперевозок по модели Uber for Х)',
      link_title: 'Перейти',
      link: 'https://exp.port-tranzit.ru/intro',
    },
    {
      title: 'Электронная очередь',
      description: 'Для зерновых терминалов',
      link_title: 'Перейти',
      link: 'https://landing.port-tranzit.ru/',
    },
    {
      title: 'Виртуальный терминал  ',
      description: '',
      link_title: '',
    },
  ],
}

export const caseBlock = {
  shipping: {
    image: 'shipping.png',
    title: 'Заявка на перевозку',
  },
  buying: {
    image: 'buying.png',
    title: 'Заявка на покупку зерна',
  },
}

export const footerBlock = {
  title: 'ООО «ПОРТ ТРАНЗИТ ЭКСПЕДИРОВАНИЕ»',
  inn: 'ИНН 2315996340',
  address:
    'Юридический адрес: 353960, Краснодарский край, <br> с Цемдолина, ул Ленина, д. 7м',

  copyright: 'ООО Порт Транзит © 2023',
  politics: 'Политика конфиденциальности',
  url: '/policy.pdf',
}

export const partnersBlock = {
  partners: [
    'aston.png',
    'cargill.svg',
    'demetra.svg',
    'AST.svg',
    'pokrovsky.svg',
    'agro.svg',
    'step.svg',
    'zz.svg',
    'ozk.svg',
    'resource.svg',
    'south.svg',
    'efko.svg',
  ],
}

export const modalRequestBlock = {
  success: {
    title: 'Спасибо, ваша заявка отправлена!',
    description: 'В ближайшее время мы с вами свяжемся!',
  },
  error: {
    title: 'Возникли технические проблемы!',
    description: 'Попробуйте отправить заявку еще раз',
  },
  button: 'Вернуться на сайт',
}
