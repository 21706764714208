<template>
  <section class="form">
    <div class="form__block mod-image">
      <img
        :src="`/img/${content.buying.image}`"
        alt="case"
        class="form__block-image"
      />
    </div>
    <div class="form__block">
      <div class="form__data">
        <div class="font-subtitle form__data-title">
          {{ content.buying.title }}
        </div>
        <buy-form v-on="$listeners" @requested="mail" />
      </div>
    </div>
  </section>
</template>

<script>
import { caseBlock } from '@/content/data'
import BuyForm from '@/components/common/Form/BuyForm'

export default {
  name: 'BuyFormContainer',
  components: { BuyForm },
  data() {
    return {
      content: caseBlock,
    }
  },
  methods: {
    mail(val) {
      this.$emit('show-form', val)
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  display: flex
  flex-wrap: wrap
  padding: 0 $desktopHorizontalPaddings
  border-top: 1px solid $backgroundGray
  border-bottom: 1px solid $backgroundGray
  @media (max-width: 1279px)
    padding: 0 $mobileHorizontalPaddings
    flex-direction: column
  &__block
    display: flex
    flex-direction: column
    width: 50%
    padding: 3.2rem
    border-left: 1px solid $backgroundGray
    @media (max-width: 1279px)
      width: 100%
      padding: 3.2rem $mobileHorizontalPaddings
      border: 1px solid $backgroundGray
    &-image
      width: 100%
      height: 100%
      object-position: center
      border-radius: 2rem
  &__block:last-child
    border-right: 1px solid $backgroundGray
  &__data
    padding: 2.4rem 0 2.4rem
    &-title
      margin-bottom: 3.2rem
    @media (max-width: 1279px)
      order: 0
      width: 100%
      padding: 4rem 0 0
      border-right: none
      border-bottom: 1px solid $backgroundGray
  &.mod-image
    @media (max-width: 1279px)
      order: 1

//! переписать
::v-deep .form-callback
  display: flex
  flex-direction: column
  flex-wrap: wrap
  &__input
    width: 100%
    margin-bottom: 3rem
    @media (min-width: 1280px)
  //width: 12.969vw
  //margin-right: 22px
  &__checkbox
    margin-bottom: 4rem
    @media (min-width: 1280px)
  //order: 5
  //margin-bottom: 0

  &__button
    width: 100%
    height: 5rem
    background: $buttonPrimary
    color: $textWhite
    transition: background .3s ease
    @media (min-width: 1280px)
      width: 11.615vw
    &:hover
      background: $buttonHover
    &:active
      background: $buttonClicked

    &.disabled
      background: $buttonDisabled
      cursor: not-allowed
      color: #B3B8C0
      &:hover
        background: $buttonDisabled
      &:active
        background: $buttonDisabled
</style>
