<template>
  <form v-loading="isLoading" name="contacts" class="form-callback">
    <double-input
      :title="content.placeholders.from"
      :has-error="hasFromError"
      :error-text="fromError"
      class="form-callback__input"
      @input-focus="hasFromError = false"
      @input-changed="getFrom"
    />
    <double-input
      :title="content.placeholders.to"
      :has-error="hasToError"
      :error-text="toError"
      class="form-callback__input"
      @input-focus="hasToError = false"
      @input-changed="getTo"
    />
    <double-input
      :title="content.placeholders.volume"
      :has-error="hasVolumeError"
      :error-text="volumeError"
      class="form-callback__input"
      @input-focus="hasVolumeError = false"
      @input-changed="getVolume"
    />
    <double-input
      :title="content.placeholders.phone_number"
      mask="+7 (###) ###-##-##"
      :has-error="hasPhoneError"
      :error-text="phoneError"
      class="form-callback__input"
      @input-focus="hasPhoneError = false"
      @input-changed="getPhone"
    />
    <double-input
      :title="content.placeholders.fio"
      :has-error="hasFioError"
      :error-text="fioError"
      class="form-callback__input"
      @input-focus="hasFioError = false"
      @input-changed="getFio"
    />

    <el-checkbox v-model="isAgree" class="form-callback__checkbox">
      {{ content.checkbox.text }}
      <a :href="content.checkbox.url" target="_blank">
        {{ content.checkbox.link }}
      </a>
    </el-checkbox>
    <button
      :class="['form-callback__button', 'font-small', { disabled: !isAgree }]"
      @click.prevent="startRequest"
    >
      {{ content.button }}
    </button>
  </form>
</template>

<script>
import axios from 'axios'
import { formDataToShipping } from '@/content/data'
import DoubleInput from '@/components/common/Form/DoubleInput'

export default {
  name: 'LeadForm',
  components: { DoubleInput },
  data() {
    return {
      content: formDataToShipping,

      from: '',
      hasFromError: false,
      fromError: '',

      to: '',
      hasToError: false,
      toError: '',

      volume: '',
      hasVolumeError: false,
      volumeError: '',

      phone_number: '',
      hasPhoneError: false,
      phoneError: '',

      fio: '',
      hasFioError: false,
      fioError: '',

      isAgree: true,

      isLoading: false,
    }
  },
  computed: {
    hasValidationErrors() {
      return (
        this.hasFromError ||
        this.hasToError ||
        this.hasVolumeError ||
        this.hasPhoneError
      )
    },
  },
  methods: {
    getFrom(from) {
      this.from = from
    },
    getTo(to) {
      this.to = to
    },
    getVolume(volume) {
      this.volume = volume
    },
    getPhone(phone) {
      this.phone_number = phone
    },
    getFio(fio) {
      this.fio = fio
    },
    validateData() {
      if (!this.from.length) {
        this.hasFromError = true
        this.fromError = 'Поле обязательно для заполнения'
      }
      if (!this.to.length) {
        this.hasToError = true
        this.toError = 'Поле обязательно для заполнения'
      }
      if (!this.volume.length) {
        this.hasVolumeError = true
        this.volumeError = 'Поле обязательно для заполнения'
      }
      if (this.phone_number.length !== 18) {
        this.hasPhoneError = true
        this.phoneError = this.phone_number.length
          ? 'Неверный формат номера'
          : 'Поле обязательно для заполнения'
      }
    },

    startRequest() {
      if (this.isAgree) {
        this.hasFromError = false
        this.hasToError = false
        this.hasVolumeError = false
        this.hasPhoneError = false
        this.validateData()

        if (!this.hasValidationErrors) {
          this.isLoading = true
          let formData = new FormData()

          formData.append('from', this.from)
          formData.append('to', this.to)
          formData.append('volume', this.volume)
          formData.append('phone_number', this.phone_number)
          formData.append('fio', this.fio)
          axios
            .post(process.env.VUE_APP_API_URL_SHIP, formData)
            .then(() => {
              this.$emit('requested', 'success')
            })
            .catch(() => {
              this.$emit('requested', 'error')
            })
            .finally(() => (this.isLoading = false))
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form-callback__input
  border-radius: 1rem
.form-callback__button
  display: flex
  justify-content: center
  align-items: center
  border: none
  outline: none
  border-radius: 5rem
  background-color: transparent
  cursor: pointer

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner
  background: $buttonPrimary

::v-deep .el-checkbox__inner
  width: 1.6rem
  height: 1.6rem
  &::after
    height: .8rem
    left: .5rem

::v-deep .el-checkbox
  display: flex
  align-items: center
  justify-content: flex-start
  color: $textGray
  a
    color: $textGray

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label
  color: $textGray
  a
    color: $textGray
    border-bottom: 1px solid $textGray

::v-deep .el-checkbox__label
  font-family: $mulishFont
  font-weight: 600
  font-size: 1.2rem
  line-height: 1.6rem
  padding-left: 1.2rem
</style>
