export default {
  mounted() {
    window.addEventListener('load', this.getOffsets)
    window.addEventListener('resize', this.getOffsets)
  },
  beforeDestroy() {
    window.removeEventListener('load', this.getOffsets)
    window.removeEventListener('resize', this.getOffsets)
  },
  methods: {
    getOffsets() {
      this.$store.dispatch('setOffset', {
        block: this.$options.name,
        value: this.$refs.anchor.offsetTop,
      })
    },
  },
}
