<template>
  <div>
    <fixed-menu-desktop />
    <fixed-menu-mobile />
    <header-component />
    <info-block :content="appBlock" />
    <exp-advantages />
    <exp-mark />
    <info-block :content="startBlock" class="pad" />
    <shipping-form @show-form="showRequestModal" />
    <buying-form @show-form="showRequestModal" />
    <ecosystem-links />
    <exp-partners />
    <social-media />
    <footer-component />

    <modal-request-result
      :visible="modalRequestOpened"
      :result="requestResult"
      @close="modalRequestOpened = false"
    />
  </div>
</template>

<script>
import FixedMenuDesktop from '@/components/menu/FixedMenuDesktop'
import FixedMenuMobile from '@/components/menu/FixedMenuMobile'
import FooterComponent from '@/components/Footer.vue'
import HeaderComponent from '@/components/Header.vue'
import ExpAdvantages from '@/components/ExpAdvantages'
import infoBlock from '@/components/infoBlock'
import ExpMark from '@/components/ExpMark'
import SocialMedia from '@/components/SocialMedia'
import ShippingForm from '@/components/ShippingFormContainer'
import BuyingForm from '@/components/BuyFormContainer'
import EcosystemLinks from '@/components/EcosystemLinks'
import ExpPartners from '@/components/ExpPartners'
import ModalRequestResult from '@/components/common/Modals/ModalRequestResult'

import { appBlock, startBlock } from '@/content/data'
export default {
  name: 'LandingPage',
  components: {
    FixedMenuDesktop,
    FixedMenuMobile,
    infoBlock,
    HeaderComponent,
    ExpAdvantages,
    FooterComponent,
    ExpMark,
    SocialMedia,
    ShippingForm,
    BuyingForm,
    EcosystemLinks,
    ExpPartners,
    ModalRequestResult,
  },
  data() {
    return {
      modalRequestOpened: false,
      requestResult: '',

      appBlock,
      startBlock,
    }
  },
  methods: {
    // вывод сообщения о результате отправки
    showRequestModal(result) {
      this.requestResult = result
      this.modalRequestOpened = true
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .v-modal
  background: rgba(25, 31, 38, 0.7)
.pad
  padding-left: $desktopHorizontalPaddings
  padding-right: $desktopHorizontalPaddings
  @media (max-width: 1280px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings
</style>
