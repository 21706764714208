<template>
  <header>
    <div class="desktop">
      <div class="desktop__left">
        <div class="desktop__left-empty" />
        <div class="desktop__left-geometry" />
      </div>
      <div class="desktop__main">
        <div v-html-safe="content.title" class="desktop__main-title" />
        <div class="desktop__main-content">
          <div class="description">{{ content.description }}</div>
          <div class="button">
            <more-button
              :title="content.button"
              @on-click="goToSection('ShippingFormContainer')"
            />
          </div>
        </div>
      </div>
      <div class="desktop__picture">
        <img
          :src="`/img/${content.image}`"
          alt="header"
          class="desktop__picture-image"
        />
      </div>
    </div>
    <div class="mobile">
      <div class="mobile__block mod-top">
        <div class="mobile__block-empty" />
        <div class="mobile__block-content">
          <div v-html-safe="content.title" class="title" />
          <div class="font-small description">{{ content.description }}</div>
          <more-button :title="content.button" v-on="$listeners" />
        </div>
      </div>
      <div class="mobile__block mod-bottom">
        <div class="mobile__block-empty" />
        <div class="mobile__block-image">
          <img
            :src="`/img/${content.image_mobile}`"
            alt="header"
            class="picture"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { headerBlock } from '@/content/data'
import menuMixin from '@/mixins/menuMixin'
import MoreButton from '@/components/common/MoreButton'

export default {
  name: 'HeaderComponent',
  components: { MoreButton },
  mixins: [menuMixin],
  data() {
    return {
      content: headerBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.desktop
  position: relative
  display: flex
  height: 84rem
  background: $textPrimary
  @media (max-width: 1279px)
    display: none
  &__left
    width: $desktopHorizontalPaddings
    border-right: 1px solid $buttonPrimary
    &-empty
      height: 67.6%
      border-bottom: 1px solid $buttonPrimary
    &-geometry
      height: 32.4%
      background-image: linear-gradient(to bottom right, transparent calc(50% - 1px), $buttonPrimary, transparent calc(50% + 1px))
      background-repeat: no-repeat
  &__main
    flex: 1
    &-title
      display: flex
      align-items: flex-end
      height: 67.6%
      padding: 0 5rem 6rem
      border-bottom: 1px solid $buttonPrimary
      font-family: $primaryFont
      font-weight: 500
      font-size: 8rem
      color: $textWhite
      line-height: 1.08
      @media (min-width: 1280px) and (max-width: 1599px)
        padding: 0 4rem 6rem
        font-size: 6rem
    &-content
      display: flex
      justify-content: space-between
      height: 32.4%
      padding: 5rem 3.9rem 0 5rem
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.8rem
      line-height: 2.8rem
      color: $textLightWhite
      .description
        width: 65%
      .button
        height: fit-content
  &__picture
    width: 33.6vw
    &-image
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center

.mobile
  display: block
  background: $textPrimary
  @media (min-width: 1280px)
    display: none
  &__block
    display: flex
    &.mod-top
      height: 39rem
      border-bottom: 1px solid $buttonPrimary
    &.mod-bottom
      height: 16rem
    &-empty
      width: 10.9%
      border-right: 1px solid $buttonPrimary
    &-content, &-image
      flex: 1
      .picture
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
    &-content
      display: flex
      flex-direction: column
      justify-content: flex-end
      padding: 0 1.4rem 3.1rem 2rem
      .title
        font-family: $primaryFont
        font-weight: 600
        font-size: 3.8rem
        line-height: 4.4rem
        color: $textWhite
      .description
        width: 26.5rem
        margin: 2.8rem 0 3rem
        line-height: 2rem
        color: $textWhite
</style>
