import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    blockOffset: {
      ExpAdvantages: 0,
      ShippingFormContainer: 0,
      EcosystemLinks: 0,
      ExpPartners: 0,
      SocialMedia: 0,
    },
  },
  getters: {
    getOffsets(state) {
      return state.blockOffset
    },
  },
  mutations: {
    SET_OFFSET(state, offset) {
      state.blockOffset[offset.block] = offset.value
    },
  },
  actions: {
    setOffset({ commit }, offset) {
      commit('SET_OFFSET', offset)
    },
  },
})
