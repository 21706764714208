<template>
  <section class="menu-desktop">
    <div class="menu__logo">
      <img
        src="@/assets/img/logo-dark.svg"
        alt="logo"
        class="menu__logo-picture"
      />
    </div>
    <div class="menu__items">
      <div
        v-for="(item, index) in menu.menuItems"
        :key="index"
        class="font-small menu__items-item"
        @click="goToSection(item.component)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="menu__links">
      <div class="menu__links-tel">
        <img
          src="@/assets/img/phone-logo.svg"
          alt="phone"
          class="phone-picture"
        />
        <a :href="`tel:${menu.links.phone}`" class="font-small phone-number">
          {{ menu.links.phone }}
        </a>
      </div>

      <div class="menu__links-email">
        <img
          src="@/assets/img/mail-logo.svg"
          alt="email"
          class="mail-picture"
        />
        <a :href="`mailto:${menu.links.email}`" class="font-small email-link">
          {{ menu.links.email }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from '@/mixins/menuMixin'
export default {
  name: 'FixedMenuDesktop',
  mixins: [menuMixin],
}
</script>

<style lang="sass" scoped>
@import "./desktop-styles"
</style>
