<template>
  <section ref="anchor" class="partners">
    <info-block :content="clientsBlock" />
    <div class="partners__logos">
      <div
        v-for="(partner, index) in content.partners"
        :key="index"
        class="partners__logos-item"
      >
        <div class="left-up" />
        <div class="left-down" />
        <div class="right-up" />
        <div class="right-down" />
        <div class="picture">
          <img
            v-if="partner.length"
            :src="`/img/partners/${partner}`"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { partnersBlock, clientsBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import infoBlock from '@/components/infoBlock'

export default {
  name: 'ExpPartners',
  components: { infoBlock },
  mixins: [offsetMixin],
  data() {
    return {
      content: partnersBlock,
      clientsBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.partners
  padding: 0 $desktopHorizontalPaddings
  margin-bottom: 16rem
  @media (max-width: 1279px)
    padding: 0 $mobileHorizontalPaddings
  &__title
    @media (max-width: 415px)
      font-size: 2.7rem

  &__logos
    display: flex
    flex-wrap: wrap
    @media (max-width: 1279px)
      justify-content: space-between
      flex-wrap: wrap
      margin-top: .4rem
    @media (max-width: 1279px)
      display: grid
      grid-template: 1fr / 1fr 1fr
    &-item
      margin: 0
      padding: 0
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 25%
      height: 23.8rem
      border-left: 1px solid $backgroundGray
      border-bottom: 1px solid $backgroundGray
      &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4)
        border-top: 1px solid $backgroundGray
      &:nth-of-type(4n)
        border-right: 1px solid $backgroundGray
      .left-up, .left-down, .right-up, .right-down
        position: absolute
        width: 20px
        height: 20px
        background: white
      @media (max-width: 1279px)
        width: 100%
        &:nth-of-type(even)
          border-right: 1px solid $backgroundGray
        &:nth-of-type(1), &:nth-of-type(2)
          border-bottom: none

      .left-up
        top: -2px
        left: -2px
      .left-down
        bottom: -2px
        left: -2px
      .right-up
        top: -2px
        right: -2px
      .right-down
        bottom: -2px
        right: -2px
      .picture
        height: 12.7rem
        border-radius: 18px
        @media (max-width: 1279px)
          height: 7.6rem
        img
          width: 100%
          height: 100%
          object-fit: contain
          object-position: center
</style>
