<template>
  <section :class="['info', 'title-group', { grey: content.grey }]">
    <div v-html-safe="content.subtitle" class="block-description" />
    <div v-html-safe="content.title" class="font-title banner-title" />
  </section>
</template>

<script>
export default {
  name: 'InfoBlock',
  props: {
    content: {
      type: Object,
      default: undefined,
    },
  },
}
</script>

<style lang="sass" scoped>
.info
  padding: 16rem 0 6.4rem
  @media (max-width: 1280px)
    padding: $mobileVerticalPaddings 0
.grey
  padding: 8rem $desktopHorizontalPaddings 6.4rem
  background-color: $buttonDisabled
  @media (max-width: 1280px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings
</style>
