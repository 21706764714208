<template>
  <footer class="footer">
    <div class="footer__content">
      <div v-html-safe="content.title" class="footer__content-title" />
      <div v-html-safe="content.inn" class="footer__content-inn" />
      <div v-html-safe="content.address" class="footer__content-address" />
    </div>
    <div class="footer__copyright">
      <div class="footer__copyright-port">{{ content.copyright }}</div>
      <div class="footer__copyright-politics">
        <a :href="content.url" target="_blank">
          {{ content.politics }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { footerBlock } from '@/content/data'
export default {
  name: 'FooterComponent',
  data() {
    return {
      content: footerBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.footer
  background: $textPrimary
  &__content
    padding: 4rem $desktopHorizontalPaddings 3.6rem
    font-family: $mulishFont
    font-weight: 400
    font-size: 1.4rem
    line-height: 18px
    color: $textGray
    &-title, &-inn
      margin-bottom: 1rem
    @media (max-width: 1279px)
      padding: 3.2rem $mobileHorizontalPaddings
  &__copyright
    display: flex
    justify-content: space-between
    align-items: center
    padding: 2.6rem $desktopHorizontalPaddings
    font-family: $secondaryFont
    font-size: 1.2rem
    color: $textGray
    border-top: 1px solid $textSecondary
    &-politics
      cursor: pointer
      a
        color: $textGray

    @media (max-width: 1279px)
      flex-direction: column
      align-items: flex-start
      row-gap: .4rem
      padding: 2.4rem $mobileHorizontalPaddings
</style>
