<template>
  <div id="app">
    <landing-page />
  </div>
</template>

<script>
import LandingPage from '@/views/LandingPage'
export default {
  name: 'App',
  components: {
    LandingPage,
  },
}
</script>
