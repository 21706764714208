import ArrowSvg from '@/components/menu/ArrowSvg'
export default {
  components: { ArrowSvg },
  data() {
    return {
      isDropdownHidden: true,
    }
  },
  methods: {
    showDropdown() {
      this.isDropdownHidden = !this.isDropdownHidden
      // запрет прокрутки фонового элемента на время открытия меню
      document.body.style.overflow = this.isDropdownHidden ? 'auto' : 'hidden'
    },
    swipeToBlock(block) {
      this.isDropdownHidden = true
      document.body.style.overflow = 'auto'
      this.goToSection(block)
    },
  },
}
