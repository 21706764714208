var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"anchor",staticClass:"advantages"},[_c('info-block',{attrs:{"content":_vm.content}}),_c('div',{staticClass:"advantages__content"},_vm._l((_vm.content.tiles),function(tile,index){return _c('div',{key:index,class:[
        'advantages__content-item',
        index > 3 ? 'mod-wide' : 'mod-narrow',
      ]},[(tile.img)?_c('img',{staticClass:"advantages__content-image",attrs:{"src":`/img/${tile.img}`}}):_c('div',{class:[
          'advantages__content-block',
          { 'mod-gray': tile.background },
        ]},[_c('div',{staticClass:"font-32-40"},[_vm._v(_vm._s(tile.title))]),_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(tile.text),expression:"tile.text"}],staticClass:"font-medium block__description"})])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }