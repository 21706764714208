<template>
  <section ref="anchor" class="social">
    <div class="social__left">
      <div>
        <div
          v-html-safe="content.title"
          class="social__left-title font-title"
        />
      </div>
    </div>
    <div class="social__right">
      <div
        v-for="(item, index) in content.tiles"
        :key="index"
        class="social__right-tile"
      >
        <div class="tile">
          <div v-html-safe="item.title" class="font-medium tile-title" />
          <more-button
            :title="content.button"
            :eco="content.eco"
            @on-click="goTo(item.link)"
          />
        </div>
        <div class="picture">
          <img
            v-if="item.qr.length"
            :src="`/img/social/${item.qr}`"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { socialBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import MoreButton from '@/components/common/MoreButton'

export default {
  name: 'SocialMedia',
  components: { MoreButton },
  mixins: [offsetMixin],
  data() {
    return {
      content: socialBlock,
    }
  },
  methods: {
    goTo(path) {
      const link = document.createElement('a')

      link.href = path
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()

      link.remove()
    },
  },
}
</script>

<style lang="sass" scoped>
.social
  display: flex
  padding: 0 $desktopHorizontalPaddings
  margin-bottom: 12rem
  border-top: 1px solid $backgroundGray
  border-bottom: 1px solid $backgroundGray
  @media (max-width: 1279px)
    flex-direction: column
    padding: 0
  &__left
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 50%
    border-left: 1px solid $backgroundGray
    padding: 4rem 8rem 3.2rem 4rem
    @media (max-width: 1279px)
      width: 100%
      padding: 3.2rem 1.4rem 2.5rem $mobileHorizontalPaddings
    &-title
      @media (max-width: 1279px)
        margin-bottom: 4.5rem
  &__right
    display: flex
    flex-wrap: wrap
    width: 50%
    @media (max-width: 1279px)
      width: 100%
      padding: 0
    &-tile
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 50%
      padding: 2.4rem
      @media (min-width: 1280px)
        height: 31rem
        &:first-of-type
          border-left: 1px solid $backgroundGray
          border-bottom: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(2)
          border-bottom: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(3)
          border-left: 1px solid $backgroundGray
          border-right: 1px solid $backgroundGray
        &:nth-of-type(4)
          border-right: 1px solid $backgroundGray
      @media (max-width: 1279px)
        height: 21.8rem
        padding: 2rem 1rem 3rem $mobileHorizontalPaddings
        border-top: 1px solid $backgroundGray
        &:nth-of-type(2n+1)
          border-right: 1px solid $backgroundGray
    .tile
      display: flex
      flex-direction: column
      &-title
        font-weight: 500
        margin-bottom: 1.6rem
    .picture
      align-self: flex-end
      @media (max-width: 1280px)
        display: none
</style>
