<template>
  <section ref="anchor" class="advantages">
    <info-block :content="content" />
    <div class="advantages__content">
      <div
        v-for="(tile, index) in content.tiles"
        :key="index"
        :class="[
          'advantages__content-item',
          index > 3 ? 'mod-wide' : 'mod-narrow',
        ]"
      >
        <img
          v-if="tile.img"
          :src="`/img/${tile.img}`"
          class="advantages__content-image"
        />
        <div
          v-else
          :class="[
            'advantages__content-block',
            { 'mod-gray': tile.background },
          ]"
        >
          <div class="font-32-40">{{ tile.title }}</div>
          <div v-html-safe="tile.text" class="font-medium block__description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { advantagesBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import infoBlock from '@/components/infoBlock'

export default {
  name: 'ExpAdvantages',
  components: { infoBlock },
  mixins: [offsetMixin],
  data() {
    return {
      content: advantagesBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.advantages
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1279px)
    padding: 6rem $mobileHorizontalPaddings
  &__content
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    margin-top: 6rem
    @media (max-width: 1279px)
      margin-top: 2.4rem
      .advantages__content-item
        width: 48.4%
        height: 16.2rem
        margin-bottom: 1.2rem
        &:nth-child(5)
          width: 100%

    @media (min-width: 1280px)
      & .mod-wide
        width: 20.16vw
        height: 13.59vw

      & .mod-narrow
        width: 14.84vw
        height: 14.84vw
        margin-bottom: 2rem
        .block__description
          min-height: 7.2rem

    &-image
      width: 100%
      height: 100%
      border-radius: 20px
      object-fit: cover
      object-position: center
      @media (max-width: 1279px)
        border-radius: 16px

    &-block
      display: flex
      flex-direction: column
      justify-content: space-between
      height: 100%
      padding: 3.2rem 2.4rem
      border-radius: 20px
      border: 1px solid $backgroundGray
      @media (min-width: 1440px) and (max-width: 1599px)
        padding: 2.2rem 1.4rem
      @media (min-width: 1280px) and (max-width: 1439px)
        padding: 2.2rem 1.4rem
      @media (max-width: 1279px)
        padding: 1.6rem 1.4rem
      .block__description
        font-weight: 500
        @media (min-width: 1280px) and (max-width: 1439px)
          font-size: 1.6rem
      &.mod-gray
        background: $buttonDisabled
        border-color: $buttonDisabled
</style>
