<template>
  <section ref="anchor" class="terms">
    <info-block :content="ecosystemBlock" />
    <div class="terms__content">
      <div
        v-for="(tile, index) in content.tiles"
        :key="index"
        class="terms__content-tile"
      >
        <div class="tile__content">
          <div class="tile__title font-subtitle">{{ tile.title }}</div>
          <div class="font-small mod-light">{{ tile.description }}</div>
        </div>

        <div class="tile__link">
          <more-button
            v-if="tile.link_title.length"
            :title="tile.link_title"
            :eco="content.eco"
            @on-click="goTo(tile.link)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ecosystemBlock, ecosystemLinksBlock } from '@/content/data'
import offsetMixin from '@/mixins/offsetMixin'
import infoBlock from '@/components/infoBlock'
import MoreButton from '@/components/common/MoreButton'

export default {
  name: 'EcosystemLinks',
  components: { infoBlock, MoreButton },
  mixins: [offsetMixin],
  data() {
    return {
      content: ecosystemLinksBlock,
      ecosystemBlock,
    }
  },
  methods: {
    goTo(path) {
      const link = document.createElement('a')

      link.href = path
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()

      link.remove()
    },
  },
}
</script>

<style lang="sass" scoped>
.terms
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1279px)
    padding: 0 $mobileHorizontalPaddings
  &__content
    display: flex
    justify-content: space-between
    column-gap: 1.9rem
    @media (max-width: 1279px)
      flex-direction: column
      justify-content: flex-start
      margin-top: 2.4rem
    &-tile
      display: flex
      flex-direction: column
      justify-content: space-between
      width: calc(50% - 1rem)
      height: 30rem
      padding: 3.2rem
      border-radius: 2rem
      background: $buttonDisabled
      @media (max-width: 1279px)
        width: 100%
        padding: 3rem 3rem 2.4rem
        margin-bottom: 1.6rem
        border-radius: 1.6rem
      .tile__title
        margin-bottom: 0.8rem
        @media (max-width: 1279px)
          margin-bottom: 1.8rem
</style>
